import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.webp";

export default function MenuLogo() {
  return (
    <Link to="/" className="mainLogo">
      <img
        src={logo}
        alt="Backstage Pass"
        className="logo"
        width={"250px"}
        height={"auto"}
      />
    </Link>
  );
}
